import React, { useState } from "react";
import moment from "moment";
import Carousel from "react-multi-carousel";
import BigImageModal from "../../Partials/BigImageModal";
import { setBgLoader, setErrorMessage, setUser } from "../../../store/users";
import { getUser, uploadImages, deleteImage } from "../../../services/api";
import { useDispatch } from "react-redux";
import "../../../styles/ProfileAbout.css";
import RealizedDreams from "./RealizedDreams";
import useIsMobile from "../../../hooks/useIsMobile";
import { UserAboutProps } from "src/types/profile-props";

const UserAbout = ({ user, type, dream, isFulfilled }: UserAboutProps) => {
  const [dreamBigImages, setDreamBigImages] = useState(false);
  const [currentIndex, setIndex] = useState(null);
  const dispatch = useDispatch();
  const isMobile = useIsMobile(768);

  const handleDreamBigImages = (index) => {
    setDreamBigImages(true);
    setIndex(index);
  };

  const genderTitles = {
    FEMALE: "About Her",
    MALE: "About Him",
  };
  const handleProfileImageChange = (e) => {
    dispatch(setErrorMessage(""));
    const image = [...e.target.files];
    dispatch(setBgLoader(true));
    uploadImages(image).then(() => {
      getUser().then((response) => {
        dispatch(setUser(response.data));
      });
    });
  };

  const deleteProfileImg = (imageId) => {
    deleteImage(imageId).then(() => {
      getUser().then((response) => {
        dispatch(setUser(response.data));
      });
    });
  };

  const aboutTitle =
    type === "user" ? "About Me" : genderTitles[user.gender] || "";

  function capitalizeFirstLetter(string) {
    return string
      ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      : "";
  }

  function formatDate(dateString) {
    return moment(dateString).format("D MMMM YYYY");
  }

  const renderDefaultImages = (count) => {
    const defaultImages = [];
    for (let i = 0; i < count; i++) {
      defaultImages.push(
        <div
          className="public-dream-photo default-img-public-dream row row-cols-md-4 row-cols-sm-3 row-cols-2"
          style={{ position: "relative" }}
          key={`default-${i}`}
        >
          <div className="about-missing-profile-image">
            <img
              src="/interact/imgIcon.svg"
              alt="default"
              style={{ height: "24px" }}
            />
          </div>
          {type === "user" && (
            <div>
              <label style={{ cursor: "pointer" }}>
                <input
                  className="file-upload"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    opacity: 0,
                    zIndex: 1,
                    cursor: "pointer",
                  }}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  name="image"
                  id="image"
                  multiple
                  onChange={handleProfileImageChange}
                />
                <img
                  src="/interact/add-image.svg"
                  alt=""
                  className="about-add-profile-image"
                />
              </label>
            </div>
          )}
        </div>
      );
    }
    return defaultImages;
  };

  const responsive = {
    laptop: {
      breakpoint: { max: 5000, min: 866 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 865, min: 701 },
      items: 3.5,
    },
    mobile: {
      breakpoint: { max: 700, min: 601 },
      items: 3,
    },
    mobileS: {
      breakpoint: { max: 600, min: 501 },
      items: 2.5,
    },
    mobileXS: {
      breakpoint: { max: 500, min: 200 },
      items: 2,
    },
  };

  return (
    <>
      <div className="about-biography-box d-flex shadow-card mb-3">
        {dreamBigImages && (
          <BigImageModal
            index={currentIndex}
            openBigImages={setDreamBigImages}
            dream={user}
          />
        )}
        <div className="about-title">Biography</div>
        <div className="about-biography-grid d-flex">
          <div className="about-biography-container">
            <img
              src="/biography/gender.svg"
              alt=""
              className="about-biography-image"
            />
            <div className="about-biography-content">
              <p>Gender:</p>
              <p className="biography-data">
                {capitalizeFirstLetter(user.gender)}
              </p>
            </div>
          </div>
          <div className="about-biography-container">
            <img
              src="/biography/birthday.svg"
              alt=""
              className="about-biography-image"
            />
            <div className="about-biography-content">
              <p>Birthday:</p>
              <p className="biography-data">{formatDate(user.birthday)}</p>
            </div>
          </div>
          <div className="about-biography-container">
            <img
              src="/biography/country.svg"
              alt=""
              className="about-biography-image"
            />
            <div className="about-biography-content">
              <p>Country:</p>
              <p className="biography-data">{user.country}</p>
            </div>
          </div>
          <div className="about-biography-container">
            <img
              src="/biography/city.svg"
              alt=""
              className="about-biography-image"
            />
            <div className="about-biography-content">
              <p>City:</p>
              <p className="biography-data">{user.city}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-profile-picture shadow-card mb-3 d-flex">
        <div className="about-title">Profile Pictures</div>
        <div className="carousel-about-images">
          <Carousel
            responsive={responsive}
            draggable
            itemClass="carousel-about-item"
          >
            {user.images?.map((img, index) => (
              <div key={img?.id || index} className="carousel-dream-image">
                <img
                  draggable="false"
                  src={img?.avatarUrl}
                  alt="gallery"
                  className="float-start public-dream-photo row row-cols-md-4 row-cols-sm-3 row-cols-2 "
                  onClick={() => handleDreamBigImages(index)}
                />
                {type === "user" && !isMobile && (
                  <img
                    draggable="false"
                    src="/interact/delete-image.svg"
                    alt="delete"
                    className="delete-icon-dream-photo"
                    onClick={() => deleteProfileImg(img?.id)}
                  />
                )}
              </div>
            ))}
            {renderDefaultImages(Math.max(0, 5 - user.images?.length))}
          </Carousel>
        </div>
      </div>
      {user.description && (
        <div className="about-description shadow-card mb-3 d-flex">
          <div className="about-title">{aboutTitle}</div>
          <div className="about-description-profile">{user.description}</div>
        </div>
      )}
      {dream.progress === 100 && (
        <RealizedDreams
          user={user}
          dream={dream}
          type={type}
          isFulfilled={isFulfilled}
        ></RealizedDreams>
      )}
    </>
  );
};
export default UserAbout;
